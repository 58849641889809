// file deepcode ignore HardcodedNonCryptoSecret: file contains only public api keys

export const environment = {
  env: 'prod',
  production: true,
  api: 'https://api.patientpal.io',
  wss: 'wss://hu9xd1meoi.execute-api.us-east-1.amazonaws.com/prod/',
  fbAppId: '2023765024380144',
  googleAnalyticsId: 'UA-115950284-3',
  googleMapsApiKey: 'AIzaSyDQwtD1hyMDqugLPIDCCk7GeOR5RNZRBhs',
  recaptcha3SiteKey: '6Lcnzp4UAAAAABbBfqSy6VP_8kmPIFMHRHjwt3VL',
  sentryDSN: 'https://38207e203dfb4364ad89b2c5ff55dc3d@sentry.io/1514630',
  stripePublicKey: 'pk_live_hJa6b1l3eQGnarEW6MBELgKC',
  tilledUrl: 'https://app.tilled.com',
  tilledPublicKey: 'pk_sHLNPt51xfTzcEf6kMQLLCOs5P6OA2iOedtONx5MItTMf89n0AvBV0bzv7zk5KEb9Kmm9RLNyBPGpDq1Q2PD5EO3jzxhNqnGehOX', // need to be filled before deployment
  tilledProductionMode: true,
  idleTimer: 5,
}
